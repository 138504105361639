export const serviceValueData = {
    regular: [
    "User experience(UX) and user interface(UI) design",
    "Mobile app testing and quality assurance",
    "Mobile app marketing and app store optimization(ASO)",
    "Mobile app maintenance and support",
    "Backend development and integration with APIs",
    "Data analysis and management",
    "Cloud integration and migration",
    "Cybersecurity and data protection",
    "Mobile app localization and globalization",
    "Integration with wearable devices and Internet of Things(IoT)",
    "Integration with payment systems and e - commerce platforms",
    "Customization and integration with enterprise systems",
    "Mobile app reporting and analytics",
    "Mobile app branding and visual design",
    "Integration with social media platforms",
    "Mobile app monetization strategy and consultancy",
], 
    bonus: ["Regular security updates and vulnerability assessments",
        "User engagement and retention strategy",
        "Custom training and education for clients' teams",
        "Analytics and data - driven decision making",
        "Continuous improvement through software updates",
        "Regular security updates and vulnerability assessments",
        "Consultancy on industry trends and technology advancements",
        "Customer feedback and integration"]
}